<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Calendar from 'primevue/calendar';

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    VclList,
    Calendar
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      id: this.$route.params.id,

      loading: true,
      errored: false,

      action: '',
      payable: 0,
      payees: 0,
      fields: [
        { key: "name", label: "Nome", sortable: true, },
        { key: "person_number", label: "CPF/CNPJ", sortable: true, },
        { key: "bank", label: "Banco", sortable: true, },
        { key: "agency", label: "Agência", sortable: true, },
        { key: "account", label: "Conta", sortable: true, },
        { key: "type", label: "Tipo", sortable: true, },
        { key: "value", label: "Valor", sortable: true, },
        { key: "status", label: "Status", sortable: true, },
      ],
      items: null,

      sortBy: "name",
      sortDesc: false,
      currentPage: "1",
      perPage: "10",
      filter: "",

      schedule: {
        modal: false,
        loading: false,
        date: '',
      },
      cancel: {
        modal: false,
      },
      del: {
        modal: false,
      },
    };
  },
  methods: {
    getPagamentos() {
      this.loading = true;

      api.get("pagamentos/" + this.$route.params.id).then((response) => {
        if (response.data.status == "success") {
          this.action = response.data.action;
          this.payable = response.data.payable;
          this.payees = response.data.payees;
          this.items = response.data.list;
        } else {
          this.$router.push('/pagamentos');
        }
      })
        .catch((error) => {
          this.errored = error;
          this.$router.push('/pagamentos');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showSchedule() {
      this.schedule.modal = true;
    },
    schedulePagamento() {
      if (this.schedule.date) {
        api.post("pagamentos/schedule", {
          id: this.$route.params.id,
          date: this.schedule.date
        }).then((response) => {
          if (response.data.status == "success") {
            this.schedule.modal = false;
            this.$toast.success('O agendamento foi realizado com sucesso.');
            this.getPagamentos();
          } else {
            if (response.data.message) {
              this.$toast.error(response.data.message);
            }
          }
        })
      }
    },
    cancelSchedule() {
      api.post("pagamentos/cancel", {
        id: this.$route.params.id
      }).then((response) => {
        if (response.data.status == "success") {
          this.schedule.modal = false;
          this.cancel.modal = false;
          this.getPagamentos();
        }
      })
    },
    showDelete() {
      this.del.modal = true;
    },
    delFile() {
      this.del.modal = false;

      api.delete("pagamentos/" + this.$route.params.id).then((response) => {
        if (response.data.status == "success") {
          this.$router.push('/pagamentos');
          this.$toast.success('A folha de pagamento foi excluída com sucesso.');
        } else {
          this.$toast.error('Não foi possível excluir a folha de pagamento.');
        }
      })
    },
  },
  mounted() {
    this.getPagamentos();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/pagamentos">Favorecidos</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block"><router-link tag="a" to="/pagamentos/importar">Importar
          pagamentos</router-link></li>
    </ol>

    <div id="dropdown" class="d-flex mb-4">
      <div class="totals">
        <h6 class="m-0">{{ payees }}</h6>
        <h6 class="m-0 text-secondary">Favorecidos</h6>
      </div>
      <div class="totals">
        <h6 class="m-0">{{ payable | currency }}</h6>
        <h6 class="m-0 text-secondary">Total à pagar</h6>
      </div>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Ações
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-item v-if="action == 'pending'" @click="showSchedule()">Agendar</b-dropdown-item>
        <b-dropdown-item v-if="action == 'scheduled'" @click="showSchedule()">Ver agendamento</b-dropdown-item>
        <b-dropdown-item v-if="action == 'processing'" @click="showSchedule()">Ver agendamento</b-dropdown-item>
        <b-dropdown-item v-if="action == 'processed'" @click="showSchedule()">Ver agendamento</b-dropdown-item>
        <b-dropdown-item @click="showDelete()">Excluir</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Itens por página
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="perPage" value="10">10 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="25">25 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="50">50 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="100">100 itens</b-form-radio></b-dropdown-form>
      </b-dropdown>
    </div>

    <div class="card min-vh-50">
      <div class="card-body">
        <div v-if="errored">
          Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.
        </div>
        <vcl-list v-else-if="loading" class="col-md-6"></vcl-list>
        <div v-else-if="items && items.length == 0" class="text-center">
          Nenhum favorecido encontrado.
        </div>
        <div v-else class="table-responsive">
          <b-input v-model="filter" placeholder="Busca" class="mb-4"></b-input>
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover min-vh-50" head-variant="light" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :current-page="currentPage" :per-page="perPage" :filter="filter" responsive>
            <template #cell(value)="row">
              {{ row.item.value | currency }}
            </template>
            <template #cell(status)="row">
              <span v-if="row.item.status == 'correct'" class="bx bx-check-double font-size-22 text-success align-middle"></span>
              <span v-else-if="row.item.status == 'duplicate'" class="bx bx-duplicate font-size-22 text-warning align-middle"></span>
              <span v-else class="bx bx-error-alt font-size-22 text-danger align-middle"></span>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <b-pagination v-model="currentPage" :total-rows="payees" :per-page="perPage" pills align="center"></b-pagination>

    <b-modal v-model="schedule.modal" modal-class="modal-right" hide-header hide-footer>
      <div>
        <h4>Agendar</h4>
        <template v-if="action == 'pending'">
          <p>Agende o pagamento da sua folha de pagamento</p>
          <Calendar v-model="schedule.date" dateFormat="yy-mm-dd" :minDate="new Date()" showTime inline></Calendar>
          <div class="d-flex mt-5">
            <b-button :disabled="!schedule.date" variant="default" v-on:click="schedulePagamento()">
              Agendar
            </b-button>
            <button class="btn btn-outline-link" v-on:click="schedule.modal = false;">Fechar <sup>ESC</sup></button>
          </div>
        </template>
        <tamplate v-else-if="action == 'scheduled'">
          <p>A sua folha de pagamento foi agendada</p>
          <div class="py-4 text-center">
            <h6>O processamento da folha de pagamento será às</h6>
            <h4 class="bg-soft-info p-2 rounded text-center">01/01/2024 às 14:00</h4>
          </div>
          <div class="d-flex mt-5">
            <b-button variant="danger" v-on:click="cancel.modal = true;">Cancelar</b-button>
            <button class="btn btn-outline-link" v-on:click="schedule.modal = false;">Fechar <sup>ESC</sup></button>
          </div>
        </tamplate>
        <tamplate v-else-if="action == 'processing'">
        </tamplate>
        <tamplate v-else-if="action == 'processed'">
        </tamplate>
      </div>
    </b-modal>

    <b-modal v-model="cancel.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Cancelar agendamento</h4>
        <p>Confirma cancelamento do agendamento?</p>
        <div class="d-flex">
          <button class="btn btn-default mr-3" v-on:click="cancelSchedule();">Confirmar</button>
          <button class="btn btn-outline-link" v-on:click="cancel.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="del.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Excluir arquivo</h4>
        <p>Confirma exclusão do arquivo?</p>
        <div class="d-flex">
          <button class="btn btn-default mr-3" v-on:click="delFile">Confirmar</button>
          <button class="btn btn-outline-link" v-on:click="del.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>